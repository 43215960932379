import type { TimezoneO } from "Everlaw/DateUtil";
import Arr = require("Everlaw/Core/Arr");
import * as moment from "moment-timezone";

/**
 * This module gives access to the tzdata timezone database (which we pull from Moment.js) in four
 * formats, as defined below.
 *
 * Example use:
 *
 *   import { TimezoneData } from "Everlaw/TimezoneData";
 *   TimezoneData.asFlatArray;  // or asGroupedDict, asFlatDict, asMoment
 *
 * As it turns out, there are a lot of timezones (and each has a lot more data than you'd expect),
 * so it makes more sense to compute this on the fly than to send all these formats over the wire
 * pre-computed. TimezoneData.asMoment alone is on the order of 20kB.
 *
 * Wrangling it into other formats is mildly computationally expensive. On my dev machine (which is
 * probably more powerful than many users' machines), computing TimezoneData.asFlatDict takes tens
 * of milliseconds. So these formats are only computed when used for the first time, then cached.
 *
 * If this proves to be a bottleneck, it may prove useful to add an explicit `precompute()` method,
 * and/or to actually optimize the code in this module!
 */

type TZDB_Moment = moment.MomentZone[] | MomentZoneDataOnly[]; // Pulled directly out of Moment.js
interface TZDB_GroupedDict {
    [key: string]: TimezoneWithOffset[];
}
interface TZDB_FlatDict {
    [key: string]: TimezoneWithOffset;
}
type TZDB_FlatArray = TimezoneWithOffset[];

// Example data to illustrate each format. Commented out so it doesn't (pointlessly) make it into
// generated JS -- but if you uncomment, these variables should type-check properly.
//
// const exampleMoment: TZDB_Moment =
//     [
//         {
//             name: 'America/Los_Angeles',
//             abbrs: ['PST', 'PDT', 'PST', 'PDT', 'PST', 'PDT', 'PST', 'PDT', 'PST', 'PDT', 'PST'],
//             untils: [1394359200000, 1414918800000, 1425808800000, 1446368400000, 1457863200000, 1478422800000, 1489312800000, 1509872400000, 1520762400000, 1541322000000, null],
//             offsets: [480, 420, 480, 420, 480, 420, 480, 420, 480, 420, 480]
//         },
//         {
//             name: 'UTC',
//             abbrs: ['UTC'],
//             untils: [null],
//             offsets: [0]
//         }
//         // ...
//     ];
//
// const exampleGroupedDict: TZDB_GroupedDict =
//     {
//         'America/Los_Angeles': [
//             {
//                 id: 'America/Los_Angeles|PST',
//                 name: 'America/Los_Angeles',
//                 abbr: 'PST',
//                 offset: 480
//             },
//             {
//                 id: 'America/Los_Angeles|PDT',
//                 name: 'America/Los_Angeles',
//                 abbr: 'PDT',
//                 offset: 420
//             },
//         ],
//         'UTC': [
//             {
//                 id: 'UTC|UTC',
//                 name: 'UTC',
//                 abbr: 'UTC',
//                 offset: 0
//             }
//         ]
//         // ...
//     };
//
// const exampleFlatDict: TZDB_FlatDict =
//     {
//         'America/Los_Angeles|PST': {
//             id: 'America/Los_Angeles|PST',
//             name: 'America/Los_Angeles',
//             abbr: 'PST',
//             offset: 480
//         },
//         'America/Los_Angeles|PDT': {
//             id: 'America/Los_Angeles|PDT',
//             name: 'America/Los_Angeles',
//             abbr: 'PDT',
//             offset: 420
//         },
//         'UTC|UTC': {
//             id: 'UTC|UTC',
//             name: 'UTC',
//             abbr: 'UTC',
//             offset: 0
//         }
//         // ...
//     };
//
// const exampleFlatArray: TZDB_FlatArray =
//     [
//         {
//             id: 'America/Los_Angeles|PST',
//             name: 'America/Los_Angeles',
//             abbr: 'PST',
//             offset: 480
//         },
//         {
//             id: 'America/Los_Angeles|PDT',
//             name: 'America/Los_Angeles',
//             abbr: 'PDT',
//             offset: 420
//         },
//         {
//             id: 'UTC|UTC',
//             name: 'UTC',
//             abbr: 'UTC',
//             offset: 0
//         }
//         // ...
//     ];

// This should match MomentZone (defined in moment-timezone.d.ts), except that MomentZone has some
// methods in addition to data.
interface MomentZoneDataOnly {
    name: string;
    abbrs: string[];
    untils: number[];
    offsets: number[];
}

export interface TimezoneWithOffset {
    // id = name + '|' + abbr. Yes, this is redundant.
    id: TimezoneO;
    name: string;
    abbr: string;
    offset: number;
}

/**
 * You probably don't need to instantiate this class; use TimezoneData below instead.
 */
export class _TimezoneData {
    asMoment: TZDB_Moment;
    private cutoff: number;

    // cached values
    private cachedGroupedDict: TZDB_GroupedDict;
    private cachedFlatDict: TZDB_FlatDict;
    private cachedFlatArray: TZDB_FlatArray;

    /**
     * You probably don't need to instantiate this class; use TimezoneData instead. If you do need
     * to create your own instance, there are two parameters (both optional):
     *
     * @param momentDB is your source timezone data in TZDB_Moment format. This defaults to using
     *   moment-timezone's data.
     * @param cutoff is (as a unix timestamp) used to ignore old, probably-irrelevant timezones.
     *   The default is pretty much arbitrary, but it seemed reasonable from trial and error.
     */
    constructor(momentDB?: TZDB_Moment, cutoff?: number) {
        this.asMoment = momentDB || moment.tz.names().map(moment.tz.zone);
        this.cutoff = cutoff || moment.utc({ year: 1990 }).valueOf();
    }

    get asGroupedDict(): TZDB_GroupedDict {
        if (this.cachedGroupedDict) {
            return this.cachedGroupedDict;
        }
        this.cachedGroupedDict = {};
        for (let zone of this.asMoment) {
            const offsets: { [key: string]: number } = {};
            for (let i = 0; i < zone.abbrs.length; i++) {
                const abbr = zone.abbrs[i];
                const until = zone.untils[i];
                const offset = zone.offsets[i];

                if (until && until < this.cutoff) {
                    continue;
                }

                offsets[abbr] = offset;
            }
            this.cachedGroupedDict[zone.name] = Object.keys(offsets).map((key) => {
                const value = offsets[key];
                return {
                    id: (zone.name + "|" + key) as TimezoneO,
                    name: zone.name,
                    abbr: key,
                    offset: value,
                };
            });
        }
        return this.cachedGroupedDict;
    }

    get asFlatDict(): TZDB_FlatDict {
        if (this.cachedFlatDict) {
            return this.cachedFlatDict;
        }
        this.cachedFlatDict = Arr.keyBy(this.asFlatArray, (x) => x.id);
        return this.cachedFlatDict;
    }

    get asFlatArray(): TZDB_FlatArray {
        if (this.cachedFlatArray) {
            return this.cachedFlatArray;
        }
        const nestedArray = Object.keys(this.asGroupedDict).map((key) => {
            const value = this.asGroupedDict[key];
            return value.map((y) => ({
                id: (key + "|" + y.abbr) as TimezoneO,
                name: key,
                abbr: y.abbr,
                offset: y.offset,
            }));
        });
        this.cachedFlatArray = Arr.flat<TimezoneWithOffset>(nestedArray);
        return this.cachedFlatArray;
    }
}

export const TimezoneData = new _TimezoneData();
